<template>
  <v-layout class="channel-list" column justify-center>
    <DodoTopToolBar
      title="Canais"
    >
      <template v-slot:deactivator>
        <v-layout class="align-center justify-center mt-6 mr-5">
          <v-container class="d-flex flex-row-reverse align-center" fluid>
            <v-switch
              v-model="activeSwitch"
              label="Mostrar Inativos"
            ></v-switch>
          </v-container>
        </v-layout>
      </template>
    </DodoTopToolBar>
      <v-layout column grow>
        <v-data-table
          :headers="headers"
          class="channel-table secondary-background"
          :items="channels"
          :loading="loading"
          :item-key="'id'"
          :items-per-page="25"
          hide-default-footer
          elevation="0"
        >
          <template v-slot:loading>
            <v-skeleton-loader type="table-row-divider@5"></v-skeleton-loader>
          </template>
          <template v-slot:item="props">
            <tr
              @dblclick="openChannel(props.item)"
              :class="props.item.active ? '' : 'disable lighten-4 grey--text'"
            >
              <td class="text-right">
                <v-chip small outlined class="ml-0" color="grey">{{
                  props.item.type
                }}</v-chip>
              </td>
              <td>
                <v-chip
                  v-if="props.item.agent"
                  small
                  outlined
                  class="ml-0"
                  :color="props.item.active ? 'teal' : 'grey'"
                >
                  <v-icon small class="ml-0 mr-1">psychology</v-icon>
                  <!-- {{ props.item.agent.name }} -->
                  <span class="ml-1"
                    >v.{{ props.item.agent.git_tag }}
                    -
                    {{ props.item.agent.repository }}</span
                  >
                </v-chip>
              </td>
              <td></td>
              <td>{{ props.item.key }}</td>
              <td style="cursor: default">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <div v-on="on">
                      {{ props.item.name }}
                    </div>
                  </template>
                  <span>{{ props.item.description }}</span>
                </v-tooltip>
              </td>
              <td>
                <v-chip
                  v-if="props.item.contact"
                  :href="
                    props.item.type == 'WHATSAPP'
                      ? `http://wa.me/${props.item.contact}`
                      : ''
                  "
                  target="blank"
                  small
                  outlined
                  class="ml-0"
                  color="teal"
                >
                  <span v-if="props.item.type == 'WHATSAPP'">{{
                    props.item.contact | VMask("+## (##) #####-####")
                  }}</span>
                  <span v-else>{{ props.item.contact }}</span>
                </v-chip>
              </td>
              <!-- <td>{{ props.item.description }}</td> -->
              <td>
                <div class="text-center">
                  <v-btn
                    class="inline-btn"
                    color="orange darken-2"
                    text
                    v-on:click="goToConversations(props.item.id)"
                  >
                    {{ props.item.count_all }}
                    <v-icon small class="ml-1">forum</v-icon>
                  </v-btn>
                </div>
              </td>
              <td>
                <div class="text-center">
                  <v-btn
                    class="inline-btn"
                    color="pink"
                    text
                    @click="goToQuickReply(props.item.id)"
                  >
                    {{ quickReplyFilterByService(props.item.id).length}}
                    <v-icon small class="ml-1">quickreply</v-icon>
                  </v-btn>
                </div>
              </td>
              <td>
                <div class="text-center">
                  <v-btn
                    class="inline-btn"
                    color="purple"
                    text
                    @click="goToInitiatives(props.item.id)"
                  >
                    {{ initiativeFilterByService(props.item.id).length }}
                    <v-icon small class="ml-1">announcement</v-icon>
                  </v-btn>
                </div>
              </td>
              <td class="text-xs text-right">
                <v-menu bottom left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>more_vert</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="openChannel(props.item)">
                      <v-list-item-title>Editar</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </template>

          <template v-slot:no-data v-if="!loading">
            <v-alert :value="true" color="warning" icon="warning"
              >Nenhum canal encontrado</v-alert
            >
          </template>
        </v-data-table>
      </v-layout>
    <NewEditChannel
      ref="editChannel"
      :channel="{}"
      :key="newChannelKey"
      @newChannel="addChannel()"
      @tableEdited="reloadTable()"
    >
    </NewEditChannel>

    <v-btn
      style="bottom: 15px"
      absolute
      dark
      fab
      bottom
      right
      color="teal lighten-1"
      @click="openChannel({})"
      v-if="false"
    >
      <v-icon>add</v-icon>
    </v-btn>
  </v-layout>
</template>

<script>
import NewEditChannel from "./components/NewEditChannel";

import DodoTopToolBar from "../components/TopToolBar";

import RasaSessionsService from '@/services/sessions'

import { mapMutations, mapActions, mapState, mapGetters } from "vuex";
import lodash from 'lodash'

export default {
  async created() {
    await this.getChannels();
  },
  components: {
    NewEditChannel,
    DodoTopToolBar,
  },
  data() {
    return {
      newChannelKey: false,
      selectedChannel: null,
      search: null,
      activeSwitch: false,
      headers: [
        {
          text: "",
          align: "left",
          sortable: false,
          value: "type",
          width: "5%",
        },
        {
          text: "Agente",
          align: "left",
          sortable: false,
          value: "key",
          width: "5%",
        },
        {
          text: "",
          align: "left",
          sortable: false,
          value: "key",
          width: "5%",
        },
        {
          text: "Chave",
          align: "left",
          sortable: false,
          value: "key",
          width: "5%",
        },
        {
          text: "Nome",
          align: "left",
          sortable: true,
          value: "name",
          width: "15%",
        },
        {
          text: "Contato",
          align: "left",
          sortable: true,
          value: "name",
          width: "10%",
        },
        {
          text: "Conversas",
          align: "center",
          sortable: false,
          value: "actions",
          width: "5%",
        },
        {
          text: "Respostas Rápidas",
          align: "center",
          sortable: false,
          value: "actions",
          width: "12%",
        },
        {
          text: "Iniciativas",
          align: "center",
          sortable: false,
          value: "actions",
          width: "8%",
        },
        {
          text: "",
          align: "right",
          sortable: false,
          value: "actions",
          width: "3%",
        },
      ],
    };
  },
  methods: {
    ...mapActions("rasaChannelList", {
      saveChannel: "save",
    }),

    ...mapMutations("channel_list", {
      setSelectedChannel: "setSelected",
      clearSelectedChannel: "clearSelected",
    }),
    async reloadTable() {
      await this.getChannels();
    },
    async getChannels(search) {
      let params = {
        limit: 999,
        order: "name",
        search: search,
        active: !this.activeSwitch,
      };
    },
    addChannel() {
      this.resetNewChannel();
      this.reloadTable();
      this.$refs.editChannel.channel = {};
    },
    openChannel(channel) {
      this.$refs.editChannel.open(channel);
      // this.$router.push('/channel/detail/' + channel_id)
    },
    resetNewChannel() {
      this.newChannelKey = !this.newChannelKey;
    },
    goToConversations(service_id) {
      this.$router.push(`/customerService2/channel/${service_id}/conversations/list`);
    },
    goToQuickReply(service_id) {
      this.$router.push(`/customerService2/${service_id}/quick_reply/list`);

    },
    goToInitiatives(service_id) {
      this.$router.push(`/customerService2/${service_id}/initiative/list`);
    },
    initiativeFilterByService(service_id) {
      return this.initiativesList.filter(el => 
        el.service_id == service_id && el.active
      )      
    },
    quickReplyFilterByService(service_id) {
      return this.quickReplyList.filter(el => 
        el.service_id == service_id && el.active
      )
    },

  },
  computed: {
    ...mapState("initiative", {
      initiatives: (state) => state.list,
    }),
    ...mapGetters("rasaServices", {
      channels: "services",
    }),
    ...mapGetters("rasaChannelList", {
      loading: "loading",
    }),
    ...mapGetters("rasaInitiative", {
      initiativesList: "list",
    }),
    ...mapGetters("rasaQuickReplyList", {
      quickReplyList: "list",
    }),
  },
  watch: {
    activeSwitch() {
      this.getChannels();
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-column {
  width: 20%;
  text-align: left !important;
}
.channel-list {
  max-height: -webkit-fill-available;
  height: auto;
}

.box-table {
  // max-height: calc(100vh - 70px);
  height: 100%;
}

.header-channels {
  min-height: max-content;
}

.percent {
  font-size: 14px;
  font-weight: 500;

  .variation {
    font-size: 10px;
    font-weight: 400;
    line-height: 10px;
    letter-spacing: 0.5px;
  }

  .greater {
    color: #4caf50;
  }

  .less {
    color: #f44336;
  }

  &.global-average {
    font-size: 16px;
    margin: auto;

    .variation {
      font-size: 10px;
      font-weight: 400;
      line-height: 10px;
      letter-spacing: 0.5px;
    }
  }
}

.v-card {
  width: 100%;
  overflow: auto;

  .channel-table {
    width: 100%;

    .clickable {
      cursor: pointer;
    }

    .has-unpublished {
      background-color: #cbfff4;
    }

    .active-circle {
      width: 18px;
      height: 18px;
      opacity: 0.8;
      border-radius: 50%;
      margin-left: 5px;
      background-color: #f44336;

      &.is-active {
        background-color: #4caf50;
      }
    }
  }

  .channel-table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(179, 179, 179, 0.3);
    background-color: transparent;
  }

  .channel-table::-webkit-scrollbar {
    width: 6px;
    background-color: transparent;
  }

  .channel-table::-webkit-scrollbar-thumb {
    background-color: lightgray;
  }
}
</style>
