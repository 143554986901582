<template>
  <v-dialog v-model="dialog" width="600" @keydown.esc="close" persistent>
    <template v-slot:activator="{ on }">
      <div v-on.stop="on" @click.stop v-on="on">
        <slot name="activator"></slot>
      </div>
    </template>
    <v-card v-if="dialog" class="secondary-background">
      <v-card-title class="headline py-5" primary-title>
        <span>{{ channel.id ? "Editar o canal" : "Novo canal" }}</span>

        <v-spacer></v-spacer>

        <v-btn text icon @click="close">
          <v-icon>close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form class="mt-6">
          <v-text-field
            v-model="channel.name"
            label="Nome*"
            name="name"
            v-validate="{ required: true }"
            :error-messages="errors.collect('name')"
          ></v-text-field>
          <v-textarea
            v-model="channel.description"
            label="Descrição"
            rows="2"
          ></v-textarea>
          <v-row no-gutters>
            <v-col cols="5">
              <v-text-field
                v-model="channel.key"
                label="Chave*"
                name="key"
                :disabled="channel.id > 0"
                v-validate="{ required: true }"
                :error-messages="errors.collect('key')"
                class="mr-1"
              ></v-text-field>
            </v-col>
            <v-col cols="7">
              <v-combobox
                label="Tipo*"
                v-model="channel.type"
                :disabled="channel.id > 0"
                :items="types"
                class="ml-1"
              ></v-combobox>
            </v-col>
          </v-row>
          <vue-tel-input-vuetify
            v-if="channel.type == 'WHATSAPP'"
            v-model="channel.contact"
            label="Contato"
            defaultCountry="BR"
            autocomplete="off"
            @input="onInput"
          ></vue-tel-input-vuetify>
          <v-text-field
            v-else
            label="Contato"
            v-model="channel.contact"
            :items="agents"
          ></v-text-field>
          <v-text-field
            append-icon="content_copy"
            v-if="channel.id"
            :value="
              channel.gateway != null
                ? webHookLink +
                  channel.gateway.toLowerCase() +
                  `/${channel.key}/message_received`
                : 'Canal sem gateway'
            "
            label="Webhook"
            name="webhook"
            id="webHook"
            readonly
          >
            <template v-slot:append>
              <v-tooltip bottom v-model="show">
                <template v-slot:activator="{ on }">
                  <v-btn icon class="ml-3" v-on="on">
                    <v-icon
                      @mouseout="copyTrigger = false"
                      @click="copyToClipboard()"
                    >
                      content_copy
                    </v-icon>
                  </v-btn>
                </template>
                <span
                  >{{ copyTrigger ? "Copiado" : "Copiar" }}
                  <v-icon medium color="green" v-if="copyTrigger"
                    >check</v-icon
                  ></span
                >
              </v-tooltip>
            </template>
          </v-text-field>
        </v-form>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">Cancelar</v-btn>

        <v-btn color="primary" text :disabled="requesting" @click="save"
          >Confirmar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions } from "vuex";

export default {
  mounted() {
    this.$validator.localize("pt", {
      custom: {
        name: {
          required: "O campo nome é obrigatório.",
        },
        key: {
          required: "O campo chave é obrigatório.",
        },
        min_confidence: {
          min_value: "Precisa ser 1 ou maior.",
          max_value: "Precisa ser 100 ou menor.",
        },
      },
    });
  },
  data() {
    return {
      show: false,
      copyTrigger: false,
      dialog: false,
      codeArea: null,
      requesting: false,
      channel: {},
      agents: [],
      webHookLink: "https://dodo.gorillascode.com/webhook/v1/",
      types: ["SMS", "WHATSAPP"],
      gateways: ["POINTER", "WRAPPER"],
    };
  },
  methods: {
    ...mapActions("channel_list", {
      saveChannel: "save",
    }),
    copyToClipboard() {
      this.copyTrigger = true;
      let webHookURL = document.querySelector("#webHook");
      webHookURL.select();
      document.execCommand("copy");
    },
    onInput(formattedNumber, { number, valid, country }) {
      let numberFormatted = `+${country.dialCode} ${formattedNumber}`;
      this.codeArea = country.dialCode;
    },
    open(channel) {
      if (channel) {
        this.channel = Object.assign({}, channel);
        this.channel.min_confidence = this.channel.min_confidence * 100;
      } else {
        this.channel = {};
      }

      this.dialog = true;
    },
    save() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.requesting = true;
          let channelToSave = null;
          if (this.channel.id) {
            channelToSave = {
              id: this.channel.id,
              name: this.channel.name,
              key: this.channel.key,
              description: this.channel.description,
              agent_id: this.channel.agent_id,
              active: this.channel.active,
              contact:
                this.channel.type == "WHATSAPP"
                  ? `+${this.codeArea} ${this.channel.contact}`.replace(
                      /[^\w]/g,
                      ""
                    )
                  : this.channel.contact,
            };
          } else {
            channelToSave = this.channel;
            delete channelToSave.min_confidence;
          }

          if (this.channel.agent_id) {
            channelToSave.agent_id = this.channel.agent_id.value;
          }

          this.saveChannel(channelToSave)
            .then((res) => {
              this.$emit("tableEdited");
              this.dialog = false;
              this.requesting = false;

              this.$store.commit(
                "showSnackbar",
                this.channel.id
                  ? "Canal alterada com sucesso"
                  : "Canal salva com sucesso"
              );
              if (this.channel.id) {
                this.$emit("updateChannel", res);
              } else {
                this.$emit("newChannel");
                this.$emit("updateChannel", res);
              }
            })
            .catch(() => {
              this.requesting = false;
              this.$store.commit(
                "showSnackbar",
                "Ocorreu um erro ao salvar a canal"
              );
            })
            .finally(() => {
              this.$emit("tableEdited");
            });
        }
      });
    },
    close() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
</style>
